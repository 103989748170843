<template>
    <div class="intro-y box p-5 mt-5">
        <div class="flex justify-end mb-4">
            <a
                :href="`https://kruizy-rossii.com/napravleniya/${getParentSlug}${form.slug}/`"
                target="_blank"
                class="btn btn-outline-secondary w-30"
                :class="{ 'cursor-wait pointer-events-none opacity-50': loading }"
            >
                Перейти на страницу
            </a>
        </div>

        <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
            <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                <Preloader />
            </div>

            <div class="col-span-6">
                <label for="form-preview-title" class="form-label">
                    Название в превью
                    <sup v-if="v$.form.preview_title.required" class="text-theme-6">*</sup>
                </label>
                <label
                    v-if="v$.form.preview_title.$errors.length"
                    for="form-preview-title"
                    class="form-input-text-error"
                >
                    {{ getOverrideMessage(v$.form.preview_title.$errors[0].$message) }}
                </label>
                <input
                    id="form-preview-title"
                    v-model="form.preview_title"
                    type="text"
                    class="form-control w-full"
                    :class="{ 'form-input-error': v$.form.preview_title.$errors.length }"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="col-span-6">
                <label for="form-title" class="form-label">Название на детальной странице</label>
                <input
                    id="form-title"
                    v-model="form.title"
                    type="text"
                    class="form-control w-full"
                    placeholder="Введите текст или будет взято название из превью"
                    autocomplete="off"
                />
            </div>

            <div class="col-span-6">
                <label for="form-link" class="form-label">
                    Ссылка
                    <sup v-if="v$.form.slug.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.slug.$errors.length" for="form-link" class="form-input-text-error">
                    {{ getOverrideMessage(v$.form.slug.$errors[0].$message) }}
                </label>
                <input
                    id="form-link"
                    v-model="form.slug"
                    type="text"
                    class="form-control w-full"
                    :class="{ 'form-input-error': v$.form.slug.$errors.length }"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="relative col-span-6" title="Порядковый номер направления от 0 до 65535">
                <label for="form-order" class="form-label">
                    Сортировка
                    <sup v-if="v$.form.order.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.order.$errors.length" for="form-order" class="form-input-text-error">
                    {{ getOverrideMessage(v$.form.order.$errors[0].$message) }}
                </label>
                <input
                    id="form-order"
                    v-model="form.order"
                    type="text"
                    class="form-control w-full"
                    placeholder="Порядковый номер направления от 0 до 65535"
                    :class="{ 'form-input-error': v$.form.order.$errors.length }"
                    autocomplete="off"
                />
            </div>

            <div class="col-span-6">
                <label for="form-parent_id" class="form-label">Родительское направление</label>
                <Multiselect
                    id="form-parent_id"
                    v-model="form.parent_id"
                    track-by="preview_title"
                    label="preview_title"
                    value-prop="id"
                    :hide-selected="false"
                    :close-on-select="false"
                    :searchable="true"
                    :options="parentOptions"
                >
                    <template #option="{ option }">
                        {{ option.preview_title || option.title }}
                    </template>
                </Multiselect>
            </div>

            <div class="col-span-4">
                <label for="form-sale_visible" class="form-label">Текст акции на картинке</label>
                <select id="form-sale_visible" v-model="form.sale_visible" class="form-select">
                    <option :value="true">Виден</option>
                    <option :value="false">Скрыт</option>
                </select>
            </div>

            <div class="col-span-2">
                <label for="form-published" class="form-label">Опубликовано</label>
                <select id="form-published" v-model="form.published" class="form-select">
                    <option :value="true">Опубликовано</option>
                    <option :value="false">Не опубликовано</option>
                </select>
            </div>

            <div class="col-span-12">
                <label for="form-subtitle" class="form-label">Подзаголовок</label>
                <input
                    id="form-subtitle"
                    v-model="form.subtitle"
                    type="text"
                    class="form-control w-full"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="col-span-12">
                <label for="form-faq_ids" class="form-label">Вопросы и ответы</label>
                <Multiselect
                    id="form-faq_ids"
                    v-model="form.faq_ids"
                    mode="tags"
                    placeholder="Выберите несколько вопросов"
                    track-by="question"
                    label="question"
                    value-prop="id"
                    :hide-selected="false"
                    :close-on-select="false"
                    :searchable="true"
                    :options="faq"
                    no-results-text="Не найдено"
                    no-options-text="Не найдено"
                    :native="false"
                >
                    <template #option="{ option }">
                        {{ option.question }}
                    </template>
                </Multiselect>
            </div>

            <div class="col-span-12">
                <label for="form-description" class="form-label">Описание</label>
                <CustomWyswig id="form-description" v-model="form.description" class="form-control w-full" />
            </div>

            <div class="col-span-12">
                <label for="form-search-title" class="form-label">Заголовок поиска</label>
                <input
                    id="form-search-title"
                    v-model="form.search_title"
                    type="text"
                    class="form-control w-full"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <!-- баг с удалением картинки при обновлении тлько тут-->
            <div class="col-span-12">
                <label class="form-label">Изображение на детальной странице</label>
                <UploadImageNew
                    v-model="form.detail_image"
                    v-model:delete="form.detail_image_delete"
                    recommended-sizes="1170х300px"
                ></UploadImageNew>
            </div>

            <div class="relative col-span-6">
                <label class="form-label">
                    Изображение в квадратной плитке
                    <sup v-if="v$.form.preview_image_square.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.preview_image_square.$errors.length" class="form-image-text-error">
                    {{ getOverrideMessage(v$.form.preview_image_square.$errors[0].$message) }}
                </label>
                <UploadImageNew
                    v-model="form.preview_image_square"
                    recommended-sizes="270х310px"
                    :has-error="!!v$.form.preview_image_square.$errors.length"
                ></UploadImageNew>
            </div>

            <div class="relative col-span-6">
                <label class="form-label">
                    Изображение в горизонтально-прямоугольной плитке
                    <sup v-if="v$.form.preview_image_hr_rectangle.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.preview_image_hr_rectangle.$errors.length" class="form-image-text-error">
                    {{ getOverrideMessage(v$.form.preview_image_hr_rectangle.$errors[0].$message) }}
                </label>
                <UploadImageNew
                    v-model="form.preview_image_hr_rectangle"
                    recommended-sizes="570х310px"
                    :has-error="!!v$.form.preview_image_hr_rectangle.$errors.length"
                ></UploadImageNew>
            </div>

            <div class="relative col-span-6">
                <label class="form-label">
                    Изображение в вертикально-прямоугольной плитке
                    <sup v-if="v$.form.preview_image_vr_rectangle.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.preview_image_vr_rectangle.$errors.length" class="form-image-text-error">
                    {{ getOverrideMessage(v$.form.preview_image_vr_rectangle.$errors[0].$message) }}
                </label>
                <UploadImageNew
                    v-model="form.preview_image_vr_rectangle"
                    recommended-sizes="270х650px"
                    :has-error="!!v$.form.preview_image_vr_rectangle.$errors.length"
                ></UploadImageNew>
            </div>

            <div class="col-span-12">
                <label for="form-attractions-title" class="form-label">Заголовок достопримечательностей</label>
                <input
                    id="form-attractions-title"
                    v-model="form.attractions_title"
                    type="text"
                    class="form-control w-full"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="col-span-12">
                <label class="form-label font-bold">Достопримечательности</label>
                <MultiselectTable
                    v-if="form.attractions"
                    v-model="form.attractions"
                    :options="attractionOptions"
                    :header-labels="headerLabels"
                />
            </div>

            <div class="col-span-12">
                <h4 class="font-bold text-xl mb-3">Seo</h4>
                <label for="form-seo-title" class="form-label">Seo заголовок</label>
                <input
                    id="form-seo-title"
                    v-model="form.seo_title"
                    type="text"
                    class="form-control w-full"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="col-span-12">
                <label for="form-seo_text" class="form-label">Seo текст</label>
                <CustomWyswig id="form-seo_text" v-model="form.seo_text" class="form-control w-full" />
            </div>

            <div class="col-span-12" title="SEO тэг для каждого круиза на этой странице">
                <label for="form-hidden_tag" class="form-label">Скрытые тэги</label>

                <Multiselect
                    id="form-hidden_tag"
                    v-model="form.hidden_tag"
                    :options="form.hidden_tag"
                    mode="tags"
                    placeholder="SEO тэги для каждого круиза на этой странице"
                    :hide-selected="false"
                    :close-on-select="false"
                    :native="false"
                    :searchable="true"
                    :create-option="true"
                    no-results-text="Введите новый тэг и Enter чтобы создать"
                    no-options-text="Введите новый тэг и Enter чтобы создать"
                />
            </div>

            <div class="col-span-12">
                <label class="form-label font-bold">Кастомные поля</label>
                <TeamsTable v-if="form.seo" v-model="form.seo" :meta="null" :headers="seoHeaders" class="mb-3" />
                <SearchParamFillInfo />
            </div>

            <div class="col-span-12 flex items-center justify-end mt-5">
                <router-link to="#" class="btn btn-secondary w-24 mr-2 mb-2" @click.prevent="redirectToMainPage"
                    >Отмена</router-link
                >
                <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(true)">
                    Сохранить и выйти
                </button>
                <button type="submit" class="btn btn-primary mb-2" @click.prevent="save()">Сохранить</button>
            </div>
        </form>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, numeric, minValue, maxValue } from '@vuelidate/validators';
import Preloader from '@/components/preloader/Main';
import UploadImageNew from '@/components/upload-image/UploadImageNew';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import TeamsTable from '@/components/tables/TeamsTable';
import { popularDirectionsMixin } from '@/mixins/form/popular-directions-mixin';
import { errorResponse } from '@/mixins/form/form-mixin';
import Multiselect from '@vueform/multiselect';
import SearchParamFillInfo from '@/components/banners/SearchParamFillInfo';
import MultiselectTable from '@/components/tables/MultiselectTable';
import { helper as $h } from '@/utils/helper';
import { formPreparation } from '@/utils/object-to-form-data';
import { pageMixin } from '@/mixins/pageMixin';

export default {
    name: 'Edit',
    components: {
        MultiselectTable,
        Preloader,
        UploadImageNew,
        CustomWyswig,
        TeamsTable,
        Multiselect,
        SearchParamFillInfo,
    },
    mixins: [popularDirectionsMixin, errorResponse, pageMixin],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            headerLabels: ['Достопримечательность'],
        };
    },

    validations() {
        return {
            form: {
                preview_title: { required },
                slug: { required },
                order: { required, numeric, minValue: minValue(0), maxValue: maxValue(65535) },
                preview_image_square: { required },
                preview_image_hr_rectangle: { required },
                preview_image_vr_rectangle: { required },
            },
        };
    },
    computed: {
        getParentSlug() {
            if (!this.form.parent_id) return '';

            const parent = this.parentOptions.filter((option) => option.id === this.form.parent_id)[0];

            return parent ? `${parent.slug}/` : '';
        },
    },
    created() {
        this.$store.commit('main/setPageHeader', 'Редактирование направления');
        this.fetchEditParams();
    },
    methods: {
        async save(redirect = false) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;

            try {
                const formData = formPreparation(this.form, {
                    nullsAsUndefineds: false,
                    imagesAsObject: true,
                });
                const { data } = await this.axios.post('/popular-directions/' + this.$route.params.id, formData, {
                    params: { _method: 'patch' },
                });

                this.form.slug = data.data.slug;

                this.$notify(data.message);

                if (redirect) {
                    this.redirectToMainPage();
                    // await this.$router.push({ name: 'popular-directions' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            } finally {
                this.loading = false;
            }
        },

        async fetchEditParams() {
            this.loading = true;

            try {
                const { data } = await this.axios.get(`/popular-directions/${this.$route.params.id}/edit`);

                // заполняем данные
                for (const [key, val] of Object.entries(data.data.popular_direction)) {
                    this.form[key] = val;
                }

                this.form.seo = this.form.seo || this.getDefaultSeo();

                this.form.faq_ids = data.data.popular_direction?.faq?.map(({ id }) => id);

                this.attractionOptions = data.data.attractions;
                this.parentOptions = data.data.directions;
            } catch (ex) {
                console.log('cannot fetch popular-direction edit params ' + ex);
                // this.$router.push('/error-page');
            } finally {
                this.loading = false;
            }
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
        redirectToMainPage() {
            this._pMTransitionToEditPageWithQuery('popular-directions');
        },
    },
};
</script>

<style scoped></style>
